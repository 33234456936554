<template>
  <v-app>
    <home-content />
  </v-app>
</template>

<script>
  export default {
    name: 'Index',
    components: {
      HomeContent: () => import ('./Content')
    },
    beforeCreate() {
      this.$store.dispatch('actionLog', { actionType: 'VISIT' })
    }
  }
</script>
